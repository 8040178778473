"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MeasurementMethod = void 0;
var MeasurementMethod;
(function (MeasurementMethod) {
  MeasurementMethod["ESTIMATED"] = "ESTIMATED";
  MeasurementMethod["DIRECT_MEASUREMENT"] = "DIRECT_MEASUREMENT";
})(MeasurementMethod || (exports.MeasurementMethod = MeasurementMethod = {}));
