"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BusinessUnitEnum = void 0;
var BusinessUnitEnum;
(function (BusinessUnitEnum) {
  BusinessUnitEnum["OPERATIONS"] = "OPERATIONS";
  BusinessUnitEnum["RESEARCH_DEVELOPMENT"] = "RESEARCH_DEVELOPMENT";
  BusinessUnitEnum["SALES_MARKETING"] = "SALES_MARKETING";
  BusinessUnitEnum["HUMAN_RESOURCES"] = "HUMAN_RESOURCES";
  BusinessUnitEnum["FINANCE"] = "FINANCE";
  BusinessUnitEnum["INFORMATION_TECHNOLOGY"] = "INFORMATION_TECHNOLOGY";
  BusinessUnitEnum["SUPPLY_CHAIN_LOGISTICS"] = "SUPPLY_CHAIN_LOGISTICS";
  BusinessUnitEnum["LEGAL_COMPLIANCE"] = "LEGAL_COMPLIANCE";
  BusinessUnitEnum["CORPORATE_SOCIAL_RESPONSIBILITY"] = "CORPORATE_SOCIAL_RESPONSIBILITY";
  BusinessUnitEnum["QUALITY_ASSURANCE"] = "QUALITY_ASSURANCE";
  BusinessUnitEnum["DFPCL"] = "DFPCL";
  BusinessUnitEnum["MAL"] = "MAL";
  BusinessUnitEnum["PCL"] = "PCL";
  BusinessUnitEnum["DMSL"] = "DMSL";
})(BusinessUnitEnum || (exports.BusinessUnitEnum = BusinessUnitEnum = {}));
