"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formRegexpMapping = exports.WEBSITE_URL_REGEXP = exports.ONLY_NUMBERS_REGEXP = exports.ONLY_NUMBERS_AND_LETTERS = exports.ONLY_NUMBERS_ONE_HYPHEN_REGEXP = void 0;
const enum_1 = require("../enum");
exports.ONLY_NUMBERS_ONE_HYPHEN_REGEXP = /^\d+(-\d+)?$/;
exports.ONLY_NUMBERS_AND_LETTERS = /^[a-zA-Z0-9]+$/;
exports.ONLY_NUMBERS_REGEXP = /^[0-9]*$/;
exports.WEBSITE_URL_REGEXP = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}(\/[a-zA-Z0-9-._~:\/?#[\]@!$&'()*+,;=]*)?$/;
exports.formRegexpMapping = {
  [enum_1.FormRegexpNames.ONLY_NUMBERS_ONE_HYPHEN_REGEXP]: exports.ONLY_NUMBERS_ONE_HYPHEN_REGEXP,
  [enum_1.FormRegexpNames.ONLY_NUMBERS_AND_LETTERS]: exports.ONLY_NUMBERS_AND_LETTERS,
  [enum_1.FormRegexpNames.ONLY_NUMBERS_REGEXP]: exports.ONLY_NUMBERS_REGEXP,
  [enum_1.FormRegexpNames.WEBSITE_URL_REGEXP]: exports.WEBSITE_URL_REGEXP
};
