"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AnswerStatus = void 0;
var AnswerStatus;
(function (AnswerStatus) {
  AnswerStatus["NOT_SENT"] = "NOT_SENT";
  AnswerStatus["SENT"] = "SENT";
  AnswerStatus["COMPLETED"] = "COMPLETED";
})(AnswerStatus || (exports.AnswerStatus = AnswerStatus = {}));
