"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getRecoveryOperation = exports.getDisposalOperation = exports.getWasteTypeOptions = exports.getWasteSourceOptions = exports.getWasteCompositionOptions = exports.getWasteMaterialOptions = exports.getMaritalStatusOptions = exports.getReligionOptions = exports.getSocioeconomicStatusOptions = exports.getLGBTQIdentityOptions = exports.getVeteranStatusOptions = exports.getIndigenousStatusOptions = exports.getNationalityOptions = exports.getEducationalLevelOptions = exports.getDisabilityOptions = exports.getEthnicityOptions = exports.getDiversityIndicatorOptions = exports.getEmployeeCategoriesOptions = exports.getGovernanceBodyCategoryOptions = exports.getEmployeeFunctionOptions = exports.getEmployeeLevelOptions = exports.getInjurySeverityOptions = exports.getInjuryOptions = exports.getWorkerTypeOptions = exports.getAuditTypeOptions = exports.getWorkerCategoryOptions = exports.getStakeholderTypeOptions = exports.getStakeholderCategoryOptions = exports.getYesNoNA = exports.getPositiveNegative = exports.getCurrencyOptions = exports.getAgeGroupOptions = exports.getRegionOptions = exports.getGenderOptions = exports.getEnergyDensityOutUnitOption = exports.getEnergyDensityInputUnitOption = exports.getEnergyInputUnitOption = exports.getEnergyOutputUnitOption = exports.getPartnerScopeOptions = exports.getPartnerTypeOptions = exports.getPartnerClassificationOptions = exports.getProductCategoryOptions = exports.getPartnerCategoryOptions = exports.getReportingPeriodOptionsList = exports.getContractTypes = exports.getUserScopes = exports.getOrgTypeOptions = exports.booleanOptions = exports.createOptionsFromValues = exports.createOptions = void 0;
exports.participationLevelOptions = exports.fundingStatusOptions = exports.planCoverageOptions = exports.typeOfBenefitPlanOptions = exports.typeOfLegalActionOptions = exports.workCategoryOptions = exports.materialCategoryOptions = exports.massAndVolumeUnitsOptions = exports.measurementMethodOptions = exports.sourceOfMaterialOptions = exports.renewableStatusOptions = exports.treatmentLevelOptions = exports.waterCategoryOptions = exports.waterAreaOptions = exports.waterSourceOptions = exports.competencyOptions = exports.underRepresentedGroupsDescriptionOptions = exports.committeeTypeOptions = exports.typeofWorker = exports.thirdPartyDescription = exports.contractualRelationshipOptions = exports.typeOfWorkOptions = exports.typeOfEmployeeOptions = exports.getTransitionAssistanceProgramOptions = exports.getTrainingProgramOptions = exports.getTrainingEmployeeCategoryOptions = exports.getEnergyEnergyFuelTypeOptions = exports.getEnergyActivityCategoriesOptions = exports.getEnergyActivityOptions = exports.getEnergyOperationOptions = exports.getEnergyReductionSourceOptions = exports.getBasisForCalculationOptions = exports.getBusinessMetricOptions = exports.getAllEnergyTypeOptions = exports.getEnergyTypeOptions = exports.getBenefitsOptions = exports.airEmissionsCalculationApproachOptions = exports.airEmissionTypeOptions = exports.odsEmissionsFactorSourceOptions = exports.odsEmissionsSubstanceOptions = exports.reductionInitiativeOptions = exports.emissionsTypeOptions = exports.baseMetricUnitOptions = exports.baseMetricOptions = exports.heatSteamUtilityTypeOptions = exports.processEmissionsUnitOption = exports.purchasedEleCalculationApproachOptions = exports.refrigerantOption = exports.activityTypeMobileOption = exports.getSiteLocation = void 0;
exports.franchiseTypeOptions = exports.franchiseMethodOptions = exports.endOfLifeWasteTreatmentOptions = exports.endOfLifeUnitOptions = exports.endOfLifeTypeOptions = exports.endOfLifeMethodOptions = exports.processingSoldQuantityOptions = exports.processingWasteQuantityOptions = exports.processingElectricityQuantityOptions = exports.processingFuelQuantityOptions = exports.processingSoldMethodOptions = exports.leasedEnergyUnitOptions = exports.leasedCoolingOptions = exports.leasedHeatingUnitOptions = exports.leasedSteamUnitOptions = exports.leasedElecticityUnitOptions = exports.leasedFuelUnitOptions = exports.leasedAssetOptions = exports.leasedBuildingOptions = exports.leasedSizeOption = exports.leasedMeteringTypeOptions = exports.leasedMethodOptions = exports.fuelEnergyUtilityOptions = exports.fuelEnergyUnitOptions = exports.fuelEnergyMethodOptions = exports.fuelEnergyTypeOptions = exports.wasteTreatmentOptions = exports.wasteUnitOptions = exports.wasteTypeOptions = exports.wasteMethodOptions = exports.getDistanceUnitOptions = exports.upstreamDownstreamStoredUnitOption = exports.upstreamDownstreamSolidUnitOption = exports.transportModeOptions = exports.upstreamDownstreamFuelCalculationMethodOptions = exports.emissionFromTransportationOptions = exports.upstreamDownstreamTypeOptions = exports.getTypeOfOperation = exports.getImpactType = exports.getBeneficiaries = exports.getInvestmentTypes = exports.getProjectStatus = exports.getInvestmentCategory = exports.impactMagnitudeOptions = exports.likelihoodOptions = exports.impactOptions = exports.timeFrameOptions = exports.riskOpportunityTypesOptions = exports.durationOptions = exports.typeOfAssistanceOptions = void 0;
exports.materialityContactType = exports.stakeholderSegment = exports.stakeholderSegmentBasis = exports.coverageCategory = exports.reversibility = exports.impactScale = exports.impactSeverity = exports.impactExposure = exports.impactFactor = exports.impactType = exports.impactCategory = exports.getPartnershipStatusOptions = exports.getRestorationSuccessOptions = exports.getImpactReversibilityOptions = exports.getImpactTypeOptions = exports.getImpactNatureOptions = exports.getBiodiversityValueAttributeOptions = exports.getOperationTypeOptions = exports.getRelationToProtectedAreaOptions = exports.getSubsurfaceLandStatusOptions = exports.getOwnershipStatusOptions = exports.getExtinctionRiskLevelOptions = exports.typeFinancial = exports.policyMethod = exports.policyCategory = exports.purchaseGoodsEmissionCategoryOptions = exports.capitalPurchasedGoodsDistanceOptions = exports.capitalPurchasedGoodsUnitOptions = exports.capitalPurchasedGoodsMethodOptions = exports.employeeCommutingTransportModeOptions = exports.employeeCommutingTeleworkingTypeOptions = exports.employeeCommutingVehicleTypeOptions = exports.employeeCommutingActivityTypeOptions = exports.employeeCommutingDistanceUnitOptions = exports.employeeCommutingFuelCalculationOptions = exports.employeeCommutingActivityOptions = exports.employeeCommutingMethodOptions = exports.getBusinessTravelDistanceUnitOptions = exports.vehicleTypeOptions = exports.travelActivityOptions = exports.businessTraveFuelCalculationMethodOptions = exports.businessTravelMethodOptions = exports.useSoldGhgOptions = exports.useSoldFuelFeedstockOptions = exports.useSoldElectricityUnitOptions = exports.useSoldMassVolumeUnitOptions = exports.useSoldMethodOptions = exports.useSoldTypeOptions = exports.franchiseBuildingTypeOptions = void 0;
const type_1 = require("../type");
const period_1 = require("../enum/period");
const stakeholder_category_1 = require("../enum/stakeholder-category");
const enum_1 = require("../enum");
const stakeholder_type_1 = require("../enum/stakeholder-type");
const activity_1 = require("../enum/activity");
const partner_1 = require("../type/partner");
const emissions_1 = require("../type/emissions");
const training_program_type_1 = require("../enum/training-program-type");
const transition_assistance_program_type_1 = require("../enum/transition-assistance-program-type");
const training_employee_category_1 = require("../enum/training-employee-category");
const water_source_1 = require("../enum/water-source");
const water_area_1 = require("../enum/water-area");
const water_category_1 = require("../enum/water-category");
const treatment_level_1 = require("../enum/treatment-level");
const renewable_status_1 = require("../enum/renewable-status");
const source_of_material_1 = require("../enum/source-of-material");
const measurement_method_1 = require("../enum/measurement-method");
const material_category_1 = require("../enum/material-category");
const createOptions = (enumTypes, translationPath) => {
  return Object.keys(enumTypes).map(item => ({
    key: item,
    label: translationPath ? `${translationPath}.${item}` : item
  }));
};
exports.createOptions = createOptions;
const createOptionsFromValues = (enumTypes, translationPath) => {
  return Object.values(enumTypes).map(item => ({
    key: item,
    label: `${translationPath}.${item}`
  }));
};
exports.createOptionsFromValues = createOptionsFromValues;
exports.booleanOptions = [{
  key: 'Yes',
  label: 'YES'
}, {
  key: 'No',
  label: 'NO'
}];
const getOrgTypeOptions = () => (0, exports.createOptions)(type_1.OrgType, 'ORG.ORG_TYPE');
exports.getOrgTypeOptions = getOrgTypeOptions;
const getUserScopes = () => (0, exports.createOptions)(type_1.UserScope, 'USER.USER_SCOPES');
exports.getUserScopes = getUserScopes;
const getContractTypes = () => (0, exports.createOptions)(type_1.ContractType, 'USER.CONTRACT_TYPES');
exports.getContractTypes = getContractTypes;
const getReportingPeriodOptionsList = () => (0, exports.createOptions)(period_1.ReportingPeriod, 'COLLECT.REPORTING_PERIODS');
exports.getReportingPeriodOptionsList = getReportingPeriodOptionsList;
const getPartnerCategoryOptions = () => (0, exports.createOptions)(partner_1.PartnerCategory, 'PARTNER.PARTNER_CATEGORY');
exports.getPartnerCategoryOptions = getPartnerCategoryOptions;
const getProductCategoryOptions = () => (0, exports.createOptions)(type_1.ProductCategory, 'PRODUCTS.CATEGORIES');
exports.getProductCategoryOptions = getProductCategoryOptions;
const getPartnerClassificationOptions = () => (0, exports.createOptions)(partner_1.Classification, 'PARTNER.PARTNER_CLASSIFICATION');
exports.getPartnerClassificationOptions = getPartnerClassificationOptions;
const getPartnerTypeOptions = () => (0, exports.createOptions)(partner_1.PartnerType, 'PARTNER.PARTNER_TYPE');
exports.getPartnerTypeOptions = getPartnerTypeOptions;
const getPartnerScopeOptions = () => (0, exports.createOptions)(partner_1.PartnerScope, 'PARTNER.PARTNER_SCOPE');
exports.getPartnerScopeOptions = getPartnerScopeOptions;
const getEnergyOutputUnitOption = () => (0, exports.createOptionsFromValues)(type_1.EnergyOutput, 'UNITS_BE');
exports.getEnergyOutputUnitOption = getEnergyOutputUnitOption;
const getEnergyInputUnitOption = () => (0, exports.createOptionsFromValues)(type_1.SolidUnit, 'UNITS_BE');
exports.getEnergyInputUnitOption = getEnergyInputUnitOption;
const getEnergyDensityInputUnitOption = () => (0, exports.createOptionsFromValues)(type_1.DensityInputUnit, 'UNITS_BE');
exports.getEnergyDensityInputUnitOption = getEnergyDensityInputUnitOption;
const getEnergyDensityOutUnitOption = () => (0, exports.createOptionsFromValues)(type_1.SolidUnit, 'UNITS_BE');
exports.getEnergyDensityOutUnitOption = getEnergyDensityOutUnitOption;
const getGenderOptions = () => (0, exports.createOptions)(activity_1.Genders, 'TOPIC.GENDERS');
exports.getGenderOptions = getGenderOptions;
const getRegionOptions = () => (0, exports.createOptions)(enum_1.RegionEnum, 'TOPIC.REGION');
exports.getRegionOptions = getRegionOptions;
const getAgeGroupOptions = () => (0, exports.createOptions)(activity_1.AgeGroups, 'TOPIC.AGE_GROUPS');
exports.getAgeGroupOptions = getAgeGroupOptions;
const getCurrencyOptions = () => (0, exports.createOptions)(type_1.Currency, 'TOPIC.CURRENCIES');
exports.getCurrencyOptions = getCurrencyOptions;
const getPositiveNegative = () => (0, exports.createOptions)(type_1.PositiveNegative, 'TOPIC.POSITIVE_NEGATIVE');
exports.getPositiveNegative = getPositiveNegative;
const getYesNoNA = () => (0, exports.createOptions)(type_1.YesNoNA, 'TOPIC.YES_NO_NA');
exports.getYesNoNA = getYesNoNA;
const getStakeholderCategoryOptions = () => (0, exports.createOptions)(stakeholder_category_1.StakeholderCategoryEnum, 'TOPICS:ANTI_CORRUPTION.COMMUNICATION_TRAINING_ABOUT_ANTI_CORRUPTION.STAKE_HOLDER_CATEGORIES');
exports.getStakeholderCategoryOptions = getStakeholderCategoryOptions;
const getStakeholderTypeOptions = () => (0, exports.createOptions)(stakeholder_type_1.StakeholderTypeEnum, 'TOPICS:ANTI_CORRUPTION.COMMUNICATION_TRAINING_ABOUT_ANTI_CORRUPTION.STAKE_HOLDER_TYPES');
exports.getStakeholderTypeOptions = getStakeholderTypeOptions;
const getWorkerCategoryOptions = () => (0, exports.createOptions)(activity_1.WorkerCategories, 'TOPICS:EMPLOYEE_HEALTH_AND_SAFETY.WORKER_CATEGORIES');
exports.getWorkerCategoryOptions = getWorkerCategoryOptions;
const getAuditTypeOptions = () => (0, exports.createOptions)(activity_1.AuditTypes, 'TOPICS:EMPLOYEE_HEALTH_AND_SAFETY.AUDIT_TYPES');
exports.getAuditTypeOptions = getAuditTypeOptions;
const getWorkerTypeOptions = () => (0, exports.createOptions)(activity_1.WorkerTypes, 'TOPICS:EMPLOYEE_HEALTH_AND_SAFETY.WORKER_TYPES');
exports.getWorkerTypeOptions = getWorkerTypeOptions;
const getInjuryOptions = () => (0, exports.createOptions)(activity_1.InjuryTypes, 'TOPICS:EMPLOYEE_HEALTH_AND_SAFETY.INJURY_TYPES');
exports.getInjuryOptions = getInjuryOptions;
const getInjurySeverityOptions = () => (0, exports.createOptions)(activity_1.InjurySeverities, 'TOPICS:EMPLOYEE_HEALTH_AND_SAFETY.INJURY_SEVERITY');
exports.getInjurySeverityOptions = getInjurySeverityOptions;
const getEmployeeLevelOptions = () => (0, exports.createOptions)(activity_1.EmployeeLevel, 'TOPICS:DIVERSITY_AND_EQUAL_OPPORTUNITY.EMPLOYEE_LEVEL');
exports.getEmployeeLevelOptions = getEmployeeLevelOptions;
const getEmployeeFunctionOptions = () => (0, exports.createOptions)(activity_1.EmployeeFunction, 'TOPICS:DIVERSITY_AND_EQUAL_OPPORTUNITY.EMPLOYEE_FUNCTION');
exports.getEmployeeFunctionOptions = getEmployeeFunctionOptions;
const getGovernanceBodyCategoryOptions = () => (0, exports.createOptions)(activity_1.GovernanceBodyCategories, 'TOPICS:DIVERSITY_AND_EQUAL_OPPORTUNITY.GOVERNANCE_BODY_CATEGORIES');
exports.getGovernanceBodyCategoryOptions = getGovernanceBodyCategoryOptions;
const getEmployeeCategoriesOptions = () => (0, exports.createOptions)(activity_1.EmployeeCategory, 'TOPICS:DIVERSITY_AND_EQUAL_OPPORTUNITY.EMPLOYEE_CATEGORIES');
exports.getEmployeeCategoriesOptions = getEmployeeCategoriesOptions;
const getDiversityIndicatorOptions = () => (0, exports.createOptions)(activity_1.DiversityIndicators, 'TOPICS:DIVERSITY_AND_EQUAL_OPPORTUNITY.DIVERSITY_INDICATORS');
exports.getDiversityIndicatorOptions = getDiversityIndicatorOptions;
const getEthnicityOptions = () => (0, exports.createOptions)(activity_1.Ethnicity, 'TOPICS:DIVERSITY_AND_EQUAL_OPPORTUNITY.ETHNICITY');
exports.getEthnicityOptions = getEthnicityOptions;
const getDisabilityOptions = () => (0, exports.createOptions)(activity_1.Disability, 'TOPICS:DIVERSITY_AND_EQUAL_OPPORTUNITY.DISABILITY');
exports.getDisabilityOptions = getDisabilityOptions;
const getEducationalLevelOptions = () => (0, exports.createOptions)(activity_1.EducationalLevel, 'TOPICS:DIVERSITY_AND_EQUAL_OPPORTUNITY.EDUCATIONAL_LEVEL');
exports.getEducationalLevelOptions = getEducationalLevelOptions;
const getNationalityOptions = () => (0, exports.createOptions)(activity_1.Nationality, 'TOPICS:DIVERSITY_AND_EQUAL_OPPORTUNITY.NATIONALITY');
exports.getNationalityOptions = getNationalityOptions;
const getIndigenousStatusOptions = () => (0, exports.createOptions)(activity_1.IndigenousStatus, 'TOPICS:DIVERSITY_AND_EQUAL_OPPORTUNITY.INDIGENOUS_STATUS');
exports.getIndigenousStatusOptions = getIndigenousStatusOptions;
const getVeteranStatusOptions = () => (0, exports.createOptions)(activity_1.VeteranStatus, 'TOPICS:DIVERSITY_AND_EQUAL_OPPORTUNITY.VETERAN_STATUS');
exports.getVeteranStatusOptions = getVeteranStatusOptions;
const getLGBTQIdentityOptions = () => (0, exports.createOptions)(activity_1.LGBTQIdentity, 'TOPICS:DIVERSITY_AND_EQUAL_OPPORTUNITY.LGBTQ_IDENTITY');
exports.getLGBTQIdentityOptions = getLGBTQIdentityOptions;
const getSocioeconomicStatusOptions = () => (0, exports.createOptions)(activity_1.SocioeconomicStatus, 'TOPICS:DIVERSITY_AND_EQUAL_OPPORTUNITY.SOCIOECONOMIC_STATUS');
exports.getSocioeconomicStatusOptions = getSocioeconomicStatusOptions;
const getReligionOptions = () => (0, exports.createOptions)(activity_1.Religion, 'TOPICS:DIVERSITY_AND_EQUAL_OPPORTUNITY.RELIGION');
exports.getReligionOptions = getReligionOptions;
const getMaritalStatusOptions = () => (0, exports.createOptions)(activity_1.MaritalStatus, 'TOPICS:DIVERSITY_AND_EQUAL_OPPORTUNITY.MARITAL_STATUS');
exports.getMaritalStatusOptions = getMaritalStatusOptions;
const getWasteMaterialOptions = () => (0, exports.createOptions)(activity_1.WasteMaterial, 'TOPICS:SOLID_WASTE.WASTE_MATERIAL');
exports.getWasteMaterialOptions = getWasteMaterialOptions;
const getWasteCompositionOptions = () => (0, exports.createOptions)(activity_1.WasteComposition, 'TOPICS:SOLID_WASTE.WASTE_COMPOSITION');
exports.getWasteCompositionOptions = getWasteCompositionOptions;
const getWasteSourceOptions = () => (0, exports.createOptions)(activity_1.WasteSource, 'TOPICS:SOLID_WASTE.WASTE_SOURCE');
exports.getWasteSourceOptions = getWasteSourceOptions;
const getWasteTypeOptions = () => (0, exports.createOptions)(activity_1.WasteType, 'TOPICS:SOLID_WASTE.WASTE_TYPE');
exports.getWasteTypeOptions = getWasteTypeOptions;
const getDisposalOperation = () => (0, exports.createOptions)(activity_1.DisposalOperation, 'TOPICS:SOLID_WASTE.DISPOSAL_OPERATION');
exports.getDisposalOperation = getDisposalOperation;
const getRecoveryOperation = () => (0, exports.createOptions)(activity_1.RecoveryOperation, 'TOPICS:SOLID_WASTE.RECOVERY_OPERATION');
exports.getRecoveryOperation = getRecoveryOperation;
const getSiteLocation = () => (0, exports.createOptions)(activity_1.SiteLocation, 'TOPICS:SOLID_WASTE.SITE_LOCATION');
exports.getSiteLocation = getSiteLocation;
const activityTypeMobileOption = () => (0, exports.createOptions)(type_1.ActivityTypeMobile, 'TOPICS:EMISSIONS.MOBILE_COMBUSTION.ACTIVITY_TYPES');
exports.activityTypeMobileOption = activityTypeMobileOption;
const refrigerantOption = () => (0, exports.createOptions)(type_1.EmissionTypeRefrigerant, 'TOPICS:EMISSIONS.REFRIGERANT.EMISSION_TYPES');
exports.refrigerantOption = refrigerantOption;
const purchasedEleCalculationApproachOptions = () => (0, exports.createOptions)(type_1.PurchasedEleCalculation, 'TOPICS:EMISSIONS.PURCHASED_ELECTRICITY.CALCULATION_APPROACHES');
exports.purchasedEleCalculationApproachOptions = purchasedEleCalculationApproachOptions;
const processEmissionsUnitOption = () => (0, exports.createOptionsFromValues)(type_1.SolidUnit, 'UNITS_BE');
exports.processEmissionsUnitOption = processEmissionsUnitOption;
const heatSteamUtilityTypeOptions = () => (0, exports.createOptions)(activity_1.HeatSteamUtilityType, 'TOPICS:EMISSIONS.STEAM_AND_HEAT.UTILITY_TYPES');
exports.heatSteamUtilityTypeOptions = heatSteamUtilityTypeOptions;
const baseMetricOptions = () => (0, exports.createOptions)(activity_1.BaseMetric, 'TOPICS:EMISSIONS.EMISSIONS_INTENSITY.BASE_METRICS');
exports.baseMetricOptions = baseMetricOptions;
const baseMetricUnitOptions = () => (0, exports.createOptions)(activity_1.BaseMetricUnit, 'TOPICS:EMISSIONS.EMISSIONS_INTENSITY.BASE_METRIC_UNITS');
exports.baseMetricUnitOptions = baseMetricUnitOptions;
const emissionsTypeOptions = () => (0, exports.createOptions)(activity_1.EmissionTypes, 'TOPICS:EMISSIONS.EMISSION_TYPES');
exports.emissionsTypeOptions = emissionsTypeOptions;
const reductionInitiativeOptions = () => (0, exports.createOptions)(activity_1.ReductionInitiative, 'TOPICS:EMISSIONS.EMISSIONS_REDUCTION.REDUCTION_INITIATIVES');
exports.reductionInitiativeOptions = reductionInitiativeOptions;
const odsEmissionsSubstanceOptions = () => (0, exports.createOptions)(activity_1.ODSEmissionsSubstances, 'TOPICS:EMISSIONS.ODS_EMISSIONS.SUBSTANCES');
exports.odsEmissionsSubstanceOptions = odsEmissionsSubstanceOptions;
const odsEmissionsFactorSourceOptions = () => (0, exports.createOptions)(activity_1.ODSEmissionsFactorSources, 'TOPICS:EMISSIONS.ODS_EMISSIONS.FACTOR_SOURCES');
exports.odsEmissionsFactorSourceOptions = odsEmissionsFactorSourceOptions;
const airEmissionTypeOptions = () => (0, exports.createOptions)(activity_1.AirEmissionTypes, 'TOPICS:EMISSIONS.AIR_EMISSIONS.AIR_EMISSION_TYPES');
exports.airEmissionTypeOptions = airEmissionTypeOptions;
const airEmissionsCalculationApproachOptions = () => (0, exports.createOptions)(activity_1.AirEmissionsCalculationApproaches, 'TOPICS:EMISSIONS.AIR_EMISSIONS.CALCULATION_APPROACHES');
exports.airEmissionsCalculationApproachOptions = airEmissionsCalculationApproachOptions;
const getBenefitsOptions = () => (0, exports.createOptions)(activity_1.Benefits, 'TOPICS:EMPLOYMENT.BENEFITS');
exports.getBenefitsOptions = getBenefitsOptions;
const getEnergyTypeOptions = () => (0, exports.createOptions)(activity_1.EnergyType, 'TOPICS:ENERGY.ENERGY_TYPES');
exports.getEnergyTypeOptions = getEnergyTypeOptions;
const getAllEnergyTypeOptions = () => (0, exports.createOptions)(activity_1.EnergyTypeReduction, 'TOPICS:ENERGY.ENERGY_TYPES');
exports.getAllEnergyTypeOptions = getAllEnergyTypeOptions;
const getBusinessMetricOptions = () => (0, exports.createOptions)(activity_1.BusinessMetric, 'TOPICS:ENERGY.BUSINESS_METRICS');
exports.getBusinessMetricOptions = getBusinessMetricOptions;
const getBasisForCalculationOptions = () => (0, exports.createOptions)(activity_1.EnergyBasisCalculation, 'TOPICS:ENERGY.CALCULATION_BASIS');
exports.getBasisForCalculationOptions = getBasisForCalculationOptions;
const getEnergyReductionSourceOptions = () => (0, exports.createOptions)(activity_1.EnergyReductionSource, 'TOPICS:ENERGY.REDUCTION_SOURCES');
exports.getEnergyReductionSourceOptions = getEnergyReductionSourceOptions;
const getEnergyOperationOptions = () => (0, exports.createOptions)(activity_1.EnergyOperation, 'TOPICS:ENERGY.OPERATIONS');
exports.getEnergyOperationOptions = getEnergyOperationOptions;
const getEnergyActivityOptions = () => (0, exports.createOptions)(activity_1.EnergyActivity, 'TOPICS:ENERGY.ACTIVITY_TYPES');
exports.getEnergyActivityOptions = getEnergyActivityOptions;
const getEnergyActivityCategoriesOptions = () => (0, exports.createOptions)(activity_1.EnergyActivityCategories, 'TOPICS:ENERGY.ACTIVITY_CATEGORIES');
exports.getEnergyActivityCategoriesOptions = getEnergyActivityCategoriesOptions;
const getEnergyEnergyFuelTypeOptions = () => (0, exports.createOptions)(emissions_1.EnergyFuelType, 'ENERGY_EMISSION_FACTOR.FUEL_TYPE_OPTIONS');
exports.getEnergyEnergyFuelTypeOptions = getEnergyEnergyFuelTypeOptions;
const getTrainingEmployeeCategoryOptions = () => (0, exports.createOptionsFromValues)(training_employee_category_1.TrianingEmployeeCategory, 'TOPICS:TRAINING_AND_EDUCATION.EMPLOYEE_CATEGORY_ENUM');
exports.getTrainingEmployeeCategoryOptions = getTrainingEmployeeCategoryOptions;
const getTrainingProgramOptions = () => (0, exports.createOptionsFromValues)(training_program_type_1.TrainingProgramType, 'TOPICS:TRAINING_AND_EDUCATION.TRAINING_PROGRAMS.TRAINING_PROGRAM_TYPE_ENUM');
exports.getTrainingProgramOptions = getTrainingProgramOptions;
const getTransitionAssistanceProgramOptions = () => (0, exports.createOptionsFromValues)(transition_assistance_program_type_1.TransitionAssistanceProgramType, 'TOPICS:TRAINING_AND_EDUCATION.TRAINING_PROGRAMS.TRANSITION_ASSISTANCE_PROGRAM_TYPE_ENUM');
exports.getTransitionAssistanceProgramOptions = getTransitionAssistanceProgramOptions;
const typeOfEmployeeOptions = () => (0, exports.createOptions)(activity_1.TypeOfEmployee, 'TOPICS:GENERAL_DISCLOSURE.EMPLOYEES_BREAKDOWN_FORM.TYPE_OF_EMPLOYEES');
exports.typeOfEmployeeOptions = typeOfEmployeeOptions;
const typeOfWorkOptions = () => (0, exports.createOptions)(activity_1.TypeOfWork, 'TOPICS:GENERAL_DISCLOSURE.NON_EMPLOYEES_BREAKDOWN_FORM.TYPE_OF_WORKS');
exports.typeOfWorkOptions = typeOfWorkOptions;
const contractualRelationshipOptions = () => (0, exports.createOptions)(activity_1.ContractualRelationship, 'TOPICS:GENERAL_DISCLOSURE.NON_EMPLOYEES_BREAKDOWN_FORM.CONTRACTUAL_RELATIONSHIPS');
exports.contractualRelationshipOptions = contractualRelationshipOptions;
const thirdPartyDescription = () => (0, exports.createOptions)(activity_1.ThirdPartyDescription, 'TOPICS:GENERAL_DISCLOSURE.NON_EMPLOYEES_BREAKDOWN_FORM.THIRD_PARTY_DESCRIPTIONS');
exports.thirdPartyDescription = thirdPartyDescription;
const typeofWorker = () => (0, exports.createOptions)(activity_1.TypeOfWorker, 'TOPICS:GENERAL_DISCLOSURE.NON_EMPLOYEES_BREAKDOWN_FORM.TYPE_OF_WORKERS');
exports.typeofWorker = typeofWorker;
const committeeTypeOptions = () => (0, exports.createOptions)(activity_1.CommitteeType, 'TOPICS:GENERAL_DISCLOSURE.GOVERNANCE_STRUCTURE_AND_COMPOSITION_BREAKDOWN_FORM.COMMITTEE_TYPES');
exports.committeeTypeOptions = committeeTypeOptions;
const underRepresentedGroupsDescriptionOptions = () => (0, exports.createOptions)(activity_1.UnderRepresentedGroupsDescription, 'TOPICS:GENERAL_DISCLOSURE.GOVERNANCE_STRUCTURE_AND_COMPOSITION_BREAKDOWN_FORM.UNDER_REPRESENTED_GROUPS_DESCRIPTIONS');
exports.underRepresentedGroupsDescriptionOptions = underRepresentedGroupsDescriptionOptions;
const competencyOptions = () => (0, exports.createOptions)(activity_1.Competency, 'TOPICS:GENERAL_DISCLOSURE.GOVERNANCE_STRUCTURE_AND_COMPOSITION_BREAKDOWN_FORM.COMPETENCYS');
exports.competencyOptions = competencyOptions;
const waterSourceOptions = () => (0, exports.createOptions)(water_source_1.WaterSource, 'TOPICS:WATER_AND_EFFLUENTS.WATER_SOURCE');
exports.waterSourceOptions = waterSourceOptions;
const waterAreaOptions = () => (0, exports.createOptions)(water_area_1.WaterArea, 'TOPICS:WATER_AND_EFFLUENTS.WATER_AREA');
exports.waterAreaOptions = waterAreaOptions;
const waterCategoryOptions = () => (0, exports.createOptions)(water_category_1.WaterCategory, 'TOPICS:WATER_AND_EFFLUENTS.WATER_CATEGORY');
exports.waterCategoryOptions = waterCategoryOptions;
const treatmentLevelOptions = () => (0, exports.createOptions)(treatment_level_1.TreatmentLevel, 'TOPICS:WATER_AND_EFFLUENTS.TREATMENT_LEVEL');
exports.treatmentLevelOptions = treatmentLevelOptions;
const renewableStatusOptions = () => (0, exports.createOptions)(renewable_status_1.RenewableStatus, 'TOPICS:MATERIALS.RENEWABLE_STATUS');
exports.renewableStatusOptions = renewableStatusOptions;
const sourceOfMaterialOptions = () => (0, exports.createOptions)(source_of_material_1.SourceOfMaterial, 'TOPICS:MATERIALS.SOURCE_OF_MATERIAL');
exports.sourceOfMaterialOptions = sourceOfMaterialOptions;
const measurementMethodOptions = () => (0, exports.createOptions)(measurement_method_1.MeasurementMethod, 'TOPICS:MATERIALS.MEASUREMENT_METHOD');
exports.measurementMethodOptions = measurementMethodOptions;
const massAndVolumeUnitsOptions = () => (0, exports.createOptions)(type_1.MassAndVolumeUnits);
exports.massAndVolumeUnitsOptions = massAndVolumeUnitsOptions;
const materialCategoryOptions = () => (0, exports.createOptions)(material_category_1.MaterialCategory, 'TOPICS:MATERIALS.MATERIAL_CATEGORY');
exports.materialCategoryOptions = materialCategoryOptions;
const workCategoryOptions = () => (0, exports.createOptions)(activity_1.WorkerCategory, 'TOPICS:MARKET_PRESENCE.WAGE_RATIO.WORKER_CATEGORIES');
exports.workCategoryOptions = workCategoryOptions;
const typeOfLegalActionOptions = () => (0, exports.createOptions)(activity_1.TypeOfLegalAction, 'TOPICS:ANTI_COMPETITIVE_BEHAVIOR.LEGAL_ACTIONS_FOR_ANTI_COMPETITIVE_BEHAVIOUR_ANTI_TRUST_AND_MONOPOLY_PRACTICES.TYPE_OF_LEGAL_ACTIONS');
exports.typeOfLegalActionOptions = typeOfLegalActionOptions;
const typeOfBenefitPlanOptions = () => (0, exports.createOptions)(activity_1.TypeOfBenefitPlan, 'TOPICS:ECONOMIC_PERFORMANCE.TYPE_OF_BENEFIT_PLAN');
exports.typeOfBenefitPlanOptions = typeOfBenefitPlanOptions;
const planCoverageOptions = () => (0, exports.createOptions)(activity_1.PlanCoverage, 'TOPICS:ECONOMIC_PERFORMANCE.PLAN_COVERAGE');
exports.planCoverageOptions = planCoverageOptions;
const fundingStatusOptions = () => (0, exports.createOptions)(activity_1.FundingStatus, 'TOPICS:ECONOMIC_PERFORMANCE.FUNDING_STATUS');
exports.fundingStatusOptions = fundingStatusOptions;
const participationLevelOptions = () => (0, exports.createOptions)(activity_1.ParticipationLevel, 'TOPICS:ECONOMIC_PERFORMANCE.PARTICIPATION_LEVEL');
exports.participationLevelOptions = participationLevelOptions;
const typeOfAssistanceOptions = () => (0, exports.createOptions)(activity_1.TypeOfAssistance, 'TOPICS:ECONOMIC_PERFORMANCE.TYPE_OF_ASSISTANCE');
exports.typeOfAssistanceOptions = typeOfAssistanceOptions;
const durationOptions = () => (0, exports.createOptions)(activity_1.Duration, 'TOPICS:ECONOMIC_PERFORMANCE.DURATION');
exports.durationOptions = durationOptions;
const riskOpportunityTypesOptions = () => (0, exports.createOptions)(activity_1.RiskOpportunityTypes, 'TOPICS:ECONOMIC_PERFORMANCE.RISK_OPPORTUNITY_TYPES');
exports.riskOpportunityTypesOptions = riskOpportunityTypesOptions;
const timeFrameOptions = () => (0, exports.createOptions)(activity_1.TimeFrame, 'TOPICS:ECONOMIC_PERFORMANCE.TIME_FRAME');
exports.timeFrameOptions = timeFrameOptions;
const impactOptions = () => (0, exports.createOptions)(activity_1.Impacts, 'TOPICS:ECONOMIC_PERFORMANCE.IMPACTS');
exports.impactOptions = impactOptions;
const likelihoodOptions = () => (0, exports.createOptions)(activity_1.Likelihoods, 'TOPICS:ECONOMIC_PERFORMANCE.LIKELIHOODS');
exports.likelihoodOptions = likelihoodOptions;
const impactMagnitudeOptions = () => (0, exports.createOptions)(activity_1.ImpactMagnitude, 'TOPICS:ECONOMIC_PERFORMANCE.IMPACT_MAGNITUDE');
exports.impactMagnitudeOptions = impactMagnitudeOptions;
const getInvestmentCategory = () => (0, exports.createOptions)(type_1.investmentCategory, 'TOPICS:INDIRECT_ECONOMIC_IMPACTS.INVESTMENT_CATEGORY');
exports.getInvestmentCategory = getInvestmentCategory;
const getProjectStatus = () => (0, exports.createOptions)(type_1.ProjectStatus, 'TOPICS:INDIRECT_ECONOMIC_IMPACTS.PROJECT_STATUS');
exports.getProjectStatus = getProjectStatus;
const getInvestmentTypes = () => (0, exports.createOptions)(type_1.InvestmentTypes, 'TOPICS:INDIRECT_ECONOMIC_IMPACTS.INVESTMENT_TYPES');
exports.getInvestmentTypes = getInvestmentTypes;
const getBeneficiaries = () => (0, exports.createOptions)(type_1.Beneficiaries, 'TOPICS:INDIRECT_ECONOMIC_IMPACTS.BENEFICIARIES_LIST');
exports.getBeneficiaries = getBeneficiaries;
const getImpactType = () => (0, exports.createOptions)(type_1.ImpactType, 'TOPICS:INDIRECT_ECONOMIC_IMPACTS.IMPACT_TYPES');
exports.getImpactType = getImpactType;
const getTypeOfOperation = () => (0, exports.createOptions)(type_1.TypeOfOperation, 'TOPICS:FREEDOM_OF_ASSOCIATION.TYPE_OF_OPERATION');
exports.getTypeOfOperation = getTypeOfOperation;
const upstreamDownstreamTypeOptions = () => (0, exports.createOptions)(activity_1.UpstreamDownstreamType, 'TOPICS:EMISSIONS.UPSTREAM_DOWNSTREAM_TRANSPORT_AND_DISTRIBUTION.TYPES');
exports.upstreamDownstreamTypeOptions = upstreamDownstreamTypeOptions;
const emissionFromTransportationOptions = () => (0, exports.createOptions)(activity_1.EmissionFromTransportation, 'TOPICS:EMISSIONS.UPSTREAM_DOWNSTREAM_TRANSPORT_AND_DISTRIBUTION.METHODS');
exports.emissionFromTransportationOptions = emissionFromTransportationOptions;
const upstreamDownstreamFuelCalculationMethodOptions = () => (0, exports.createOptions)(activity_1.UpstreamDownstreamFuelCalculationMethod, 'TOPICS:EMISSIONS.UPSTREAM_DOWNSTREAM_TRANSPORT_AND_DISTRIBUTION.FUEL_CALCULATION_METHODS');
exports.upstreamDownstreamFuelCalculationMethodOptions = upstreamDownstreamFuelCalculationMethodOptions;
const transportModeOptions = () => (0, exports.createOptions)(activity_1.TransportMode, 'TOPICS:EMISSIONS.UPSTREAM_DOWNSTREAM_TRANSPORT_AND_DISTRIBUTION.TRANSPORT_MODE_VEHICLE_TYPES');
exports.transportModeOptions = transportModeOptions;
const upstreamDownstreamSolidUnitOption = () => (0, exports.createOptions)(type_1.UpstreamSolidUnit, 'TOPICS:EMISSIONS.UPSTREAM_DOWNSTREAM_TRANSPORT_AND_DISTRIBUTION.UNIT_FOR_GOODS_TRANSPORTED_OPTIONS');
exports.upstreamDownstreamSolidUnitOption = upstreamDownstreamSolidUnitOption;
const upstreamDownstreamStoredUnitOption = () => (0, exports.createOptions)(type_1.UpstremStoredUnit, 'TOPICS:EMISSIONS.UPSTREAM_DOWNSTREAM_TRANSPORT_AND_DISTRIBUTION.UNIT_OF_STORED_GOODSS');
exports.upstreamDownstreamStoredUnitOption = upstreamDownstreamStoredUnitOption;
const getDistanceUnitOptions = () => (0, exports.createOptions)(type_1.DistanceUnit, 'TOPICS:EMISSIONS.UPSTREAM_DOWNSTREAM_TRANSPORT_AND_DISTRIBUTION.DISTANCE_UNITS');
exports.getDistanceUnitOptions = getDistanceUnitOptions;
const wasteMethodOptions = () => (0, exports.createOptions)(activity_1.WasteMethod, 'TOPICS:EMISSIONS.WASTE_GENERATED_IN_OPERATIONS.METHODS');
exports.wasteMethodOptions = wasteMethodOptions;
const wasteTypeOptions = () => (0, exports.createOptions)(activity_1.WasteGeneratedType, 'TOPICS:EMISSIONS.WASTE_GENERATED_IN_OPERATIONS.WASTE_TYPES');
exports.wasteTypeOptions = wasteTypeOptions;
const wasteUnitOptions = () => (0, exports.createOptions)(type_1.WasteUnits, 'TOPICS:EMISSIONS.WASTE_GENERATED_IN_OPERATIONS.UNIT_OF_WASTE_PRODUCEDS');
exports.wasteUnitOptions = wasteUnitOptions;
const wasteTreatmentOptions = () => (0, exports.createOptions)(activity_1.WasteTreatment, 'TOPICS:EMISSIONS.WASTE_GENERATED_IN_OPERATIONS.WASTE_TREATMENT_METHODS');
exports.wasteTreatmentOptions = wasteTreatmentOptions;
const fuelEnergyTypeOptions = () => (0, exports.createOptions)(activity_1.FuelEnergyType, 'TOPICS:EMISSIONS.FUEL_AND_ENERGY.TYPES');
exports.fuelEnergyTypeOptions = fuelEnergyTypeOptions;
const fuelEnergyMethodOptions = () => (0, exports.createOptions)(activity_1.FuelEnergyMethod, 'TOPICS:EMISSIONS.FUEL_AND_ENERGY.METHODS');
exports.fuelEnergyMethodOptions = fuelEnergyMethodOptions;
const fuelEnergyUnitOptions = () => (0, exports.createOptions)(type_1.FuelEnergyUnit, 'TOPICS:EMISSIONS.FUEL_AND_ENERGY.UNITS');
exports.fuelEnergyUnitOptions = fuelEnergyUnitOptions;
const fuelEnergyUtilityOptions = () => (0, exports.createOptions)(activity_1.fuelEnergyUtilityType, 'TOPICS:EMISSIONS.FUEL_AND_ENERGY.UTILITY_TYPES');
exports.fuelEnergyUtilityOptions = fuelEnergyUtilityOptions;
const leasedMethodOptions = () => (0, exports.createOptions)(activity_1.leasedMethod, 'TOPICS:EMISSIONS.LEASED_ASSETS.METHODS');
exports.leasedMethodOptions = leasedMethodOptions;
const leasedMeteringTypeOptions = () => (0, exports.createOptions)(activity_1.leasedMeteringType, 'TOPICS:EMISSIONS.LEASED_ASSETS.METERING_TYPES');
exports.leasedMeteringTypeOptions = leasedMeteringTypeOptions;
const leasedSizeOption = () => (0, exports.createOptions)(activity_1.leasedSizeUnit, 'TOPICS:EMISSIONS.LEASED_ASSETS.SIZE_UNITS');
exports.leasedSizeOption = leasedSizeOption;
const leasedBuildingOptions = () => (0, exports.createOptions)(activity_1.leasedBuilding, 'TOPICS:EMISSIONS.LEASED_ASSETS.BUILDING_TYPES');
exports.leasedBuildingOptions = leasedBuildingOptions;
const leasedAssetOptions = () => (0, exports.createOptions)(activity_1.leasedAsset, 'TOPICS:EMISSIONS.LEASED_ASSETS.ASSET_TYPES');
exports.leasedAssetOptions = leasedAssetOptions;
const leasedFuelUnitOptions = () => (0, exports.createOptions)(type_1.LeasedFuelUnit, 'TOPICS:EMISSIONS.LEASED_ASSETS.FUEL_QUANTITY_UNITS');
exports.leasedFuelUnitOptions = leasedFuelUnitOptions;
const leasedElecticityUnitOptions = () => (0, exports.createOptions)(type_1.LeasedElectricityUnit, 'TOPICS:EMISSIONS.LEASED_ASSETS.ELECTRICITY_QUANTITY_UNITS');
exports.leasedElecticityUnitOptions = leasedElecticityUnitOptions;
const leasedSteamUnitOptions = () => (0, exports.createOptions)(type_1.LeasedSteamUnit, 'TOPICS:EMISSIONS.LEASED_ASSETS.STEAM_QUANTITY_UNITS');
exports.leasedSteamUnitOptions = leasedSteamUnitOptions;
const leasedHeatingUnitOptions = () => (0, exports.createOptions)(type_1.LeasedHeatingUnit, 'TOPICS:EMISSIONS.LEASED_ASSETS.HEATING_QUANTITY_UNITS');
exports.leasedHeatingUnitOptions = leasedHeatingUnitOptions;
const leasedCoolingOptions = () => (0, exports.createOptions)(type_1.LeasedCoolingUnit, 'TOPICS:EMISSIONS.LEASED_ASSETS.COOLING_QUANTITY_UNITS');
exports.leasedCoolingOptions = leasedCoolingOptions;
const leasedEnergyUnitOptions = () => (0, exports.createOptions)(type_1.LeasedEnergyUnit, 'TOPICS:EMISSIONS.LEASED_ASSETS.ENERGY_UNITS');
exports.leasedEnergyUnitOptions = leasedEnergyUnitOptions;
const processingSoldMethodOptions = () => (0, exports.createOptions)(activity_1.ProcessingSoldMethod, 'TOPICS:EMISSIONS.PROCESSING_OF_SOLD_PRODUCT.METHODS');
exports.processingSoldMethodOptions = processingSoldMethodOptions;
const processingFuelQuantityOptions = () => (0, exports.createOptions)(type_1.ProcessingMassVolumeUnit, 'TOPICS:EMISSIONS.PROCESSING_OF_SOLD_PRODUCT.UNITS');
exports.processingFuelQuantityOptions = processingFuelQuantityOptions;
const processingElectricityQuantityOptions = () => (0, exports.createOptions)(type_1.ProcessingElectricityQuantityUnit, 'TOPICS:EMISSIONS.PROCESSING_OF_SOLD_PRODUCT.ELECTRICITY_QUANTITY_UNITS');
exports.processingElectricityQuantityOptions = processingElectricityQuantityOptions;
const processingWasteQuantityOptions = () => (0, exports.createOptions)(type_1.ProcessingMassVolumeUnit, 'TOPICS:EMISSIONS.PROCESSING_OF_SOLD_PRODUCT.UNITS');
exports.processingWasteQuantityOptions = processingWasteQuantityOptions;
const processingSoldQuantityOptions = () => (0, exports.createOptions)(type_1.ProcessingMassVolumeUnit, 'TOPICS:EMISSIONS.PROCESSING_OF_SOLD_PRODUCT.UNITS');
exports.processingSoldQuantityOptions = processingSoldQuantityOptions;
const endOfLifeMethodOptions = () => (0, exports.createOptions)(activity_1.EndOfLifeMethod, 'TOPICS:EMISSIONS.END_OF_LIFE_SOLD_PRODUCT.METHODS');
exports.endOfLifeMethodOptions = endOfLifeMethodOptions;
const endOfLifeTypeOptions = () => (0, exports.createOptions)(activity_1.EndOfLifeType, 'TOPICS:EMISSIONS.END_OF_LIFE_SOLD_PRODUCT.TYPES');
exports.endOfLifeTypeOptions = endOfLifeTypeOptions;
const endOfLifeUnitOptions = () => (0, exports.createOptions)(type_1.EndOfLifeMassVolumeUnit, 'TOPICS:EMISSIONS.END_OF_LIFE_SOLD_PRODUCT.UNITS');
exports.endOfLifeUnitOptions = endOfLifeUnitOptions;
const endOfLifeWasteTreatmentOptions = () => (0, exports.createOptions)(activity_1.WasteTreatment, 'TOPICS:EMISSIONS.END_OF_LIFE_SOLD_PRODUCT.WASTE_TREATMENT_METHODS');
exports.endOfLifeWasteTreatmentOptions = endOfLifeWasteTreatmentOptions;
const franchiseMethodOptions = () => (0, exports.createOptions)(activity_1.FranchiseMethod, 'TOPICS:EMISSIONS.FRANCHISES.METHODS');
exports.franchiseMethodOptions = franchiseMethodOptions;
const franchiseTypeOptions = () => (0, exports.createOptions)(activity_1.FranchiseType, 'TOPICS:EMISSIONS.FRANCHISES.TYPES');
exports.franchiseTypeOptions = franchiseTypeOptions;
const franchiseBuildingTypeOptions = () => (0, exports.createOptions)(activity_1.FranchiseBuildingType, 'TOPICS:EMISSIONS.FRANCHISES.BUILDING_TYPES');
exports.franchiseBuildingTypeOptions = franchiseBuildingTypeOptions;
const useSoldTypeOptions = () => (0, exports.createOptions)(activity_1.UseSoldType, 'TOPICS:EMISSIONS.USE_OF_SOLD_PRODUCT.TYPES');
exports.useSoldTypeOptions = useSoldTypeOptions;
const useSoldMethodOptions = () => (0, exports.createOptions)(activity_1.UseSoldMethod, 'TOPICS:EMISSIONS.USE_OF_SOLD_PRODUCT.METHODS');
exports.useSoldMethodOptions = useSoldMethodOptions;
const useSoldMassVolumeUnitOptions = () => (0, exports.createOptions)(type_1.UseOfSoldMassVolumeUnit, 'TOPICS:EMISSIONS.USE_OF_SOLD_PRODUCT.UNITS');
exports.useSoldMassVolumeUnitOptions = useSoldMassVolumeUnitOptions;
const useSoldElectricityUnitOptions = () => (0, exports.createOptions)(type_1.UseOfSoldElectricityUnit, 'TOPICS:EMISSIONS.USE_OF_SOLD_PRODUCT.ELECTRICITY_UNITS');
exports.useSoldElectricityUnitOptions = useSoldElectricityUnitOptions;
const useSoldFuelFeedstockOptions = () => (0, exports.createOptions)(activity_1.FuelAndFeedStock, 'TOPICS:EMISSIONS.USE_OF_SOLD_PRODUCT.FUEL_AND_FEEDSTOCKS');
exports.useSoldFuelFeedstockOptions = useSoldFuelFeedstockOptions;
const useSoldGhgOptions = () => (0, exports.createOptions)(activity_1.Ghg, 'TOPICS:EMISSIONS.USE_OF_SOLD_PRODUCT.GHGS');
exports.useSoldGhgOptions = useSoldGhgOptions;
const businessTravelMethodOptions = () => (0, exports.createOptions)(activity_1.BusinessTravelMethods, 'TOPICS:EMISSIONS.BUSINESS_TRAVEL.METHODS');
exports.businessTravelMethodOptions = businessTravelMethodOptions;
const businessTraveFuelCalculationMethodOptions = () => (0, exports.createOptions)(activity_1.BusinessTraveFuelCalculationMethods, 'TOPICS:EMISSIONS.BUSINESS_TRAVEL.FUEL_CALCULATION_METHODS');
exports.businessTraveFuelCalculationMethodOptions = businessTraveFuelCalculationMethodOptions;
const travelActivityOptions = () => (0, exports.createOptions)(activity_1.TravelMethods, 'TOPICS:EMISSIONS.BUSINESS_TRAVEL.TRAVEL_ACTIVITIES');
exports.travelActivityOptions = travelActivityOptions;
const vehicleTypeOptions = () => (0, exports.createOptions)(activity_1.VehicleTypes, 'TOPICS:EMISSIONS.BUSINESS_TRAVEL.VEHICLE_TYPES');
exports.vehicleTypeOptions = vehicleTypeOptions;
const getBusinessTravelDistanceUnitOptions = () => (0, exports.createOptions)(type_1.BusinessTravelDistanceUnit, 'TOPICS:EMISSIONS.BUSINESS_TRAVEL.UNIT_OF_DISTANCE_TRAVELLES');
exports.getBusinessTravelDistanceUnitOptions = getBusinessTravelDistanceUnitOptions;
const employeeCommutingMethodOptions = () => (0, exports.createOptions)(activity_1.EmployeeCommutingMethods, 'TOPICS:EMISSIONS.EMPLOYEE_COMMUTING.METHODS');
exports.employeeCommutingMethodOptions = employeeCommutingMethodOptions;
const employeeCommutingActivityOptions = () => (0, exports.createOptions)(activity_1.CommutingActivity, 'TOPICS:EMISSIONS.EMPLOYEE_COMMUTING.COMMUTING_ACTIVITIES');
exports.employeeCommutingActivityOptions = employeeCommutingActivityOptions;
const employeeCommutingFuelCalculationOptions = () => (0, exports.createOptions)(activity_1.ECFuelBasedCalculationMethods, 'TOPICS:EMISSIONS.EMPLOYEE_COMMUTING.FUEL_CALCULATION_METHODS');
exports.employeeCommutingFuelCalculationOptions = employeeCommutingFuelCalculationOptions;
const employeeCommutingDistanceUnitOptions = () => (0, exports.createOptions)(type_1.DistanceUnit, 'TOPICS:EMISSIONS.EMPLOYEE_COMMUTING.DISTANCE_UNITS');
exports.employeeCommutingDistanceUnitOptions = employeeCommutingDistanceUnitOptions;
const employeeCommutingActivityTypeOptions = () => (0, exports.createOptions)(activity_1.ECDistanceBasedMethod, 'TOPICS:EMISSIONS.EMPLOYEE_COMMUTING.ACTIVITY_TYPES');
exports.employeeCommutingActivityTypeOptions = employeeCommutingActivityTypeOptions;
const employeeCommutingVehicleTypeOptions = () => (0, exports.createOptions)(activity_1.CommputingVehicleType, 'TOPICS:EMISSIONS.EMPLOYEE_COMMUTING.VEHICLE_TYPES');
exports.employeeCommutingVehicleTypeOptions = employeeCommutingVehicleTypeOptions;
const employeeCommutingTeleworkingTypeOptions = () => (0, exports.createOptions)(activity_1.TeleWorkingType, 'TOPICS:EMISSIONS.EMPLOYEE_COMMUTING.TELEWORKING_TYPES');
exports.employeeCommutingTeleworkingTypeOptions = employeeCommutingTeleworkingTypeOptions;
const employeeCommutingTransportModeOptions = () => (0, exports.createOptions)(activity_1.CommputingTansportMode, 'TOPICS:EMISSIONS.EMPLOYEE_COMMUTING.TRANSPORT_MODES');
exports.employeeCommutingTransportModeOptions = employeeCommutingTransportModeOptions;
const capitalPurchasedGoodsMethodOptions = () => (0, exports.createOptions)(activity_1.CapitalPurchasedGoodsMethod, 'TOPICS:EMISSIONS.CAPITAL_GOODS.METHODS');
exports.capitalPurchasedGoodsMethodOptions = capitalPurchasedGoodsMethodOptions;
const capitalPurchasedGoodsUnitOptions = () => (0, exports.createOptions)(type_1.UseOfSoldMassVolumeUnit, 'TOPICS:EMISSIONS.CAPITAL_GOODS.UNITS');
exports.capitalPurchasedGoodsUnitOptions = capitalPurchasedGoodsUnitOptions;
const capitalPurchasedGoodsDistanceOptions = () => (0, exports.createOptions)(type_1.DistanceUnit, 'TOPICS:EMISSIONS.CAPITAL_GOODS.DISTANCE_UNITS');
exports.capitalPurchasedGoodsDistanceOptions = capitalPurchasedGoodsDistanceOptions;
const purchaseGoodsEmissionCategoryOptions = () => (0, exports.createOptions)(activity_1.PurchaseGoodsEmissionCategory, 'TOPICS:EMISSIONS.PURCHASED_GOODS_AND_SERVICE.EMISSION_FACTOR_CATEGORIES');
exports.purchaseGoodsEmissionCategoryOptions = purchaseGoodsEmissionCategoryOptions;
const policyCategory = () => (0, exports.createOptions)(activity_1.PolicyCategory, 'TOPICS:PUBLIC_POLICY.POLICY_CATEGORY');
exports.policyCategory = policyCategory;
const policyMethod = () => (0, exports.createOptions)(activity_1.PolicyMethod, 'TOPICS:PUBLIC_POLICY.POLICY_METHODS');
exports.policyMethod = policyMethod;
const typeFinancial = () => (0, exports.createOptions)(activity_1.PublicPolicyType, 'TOPICS:PUBLIC_POLICY.POLICY_TYPE');
exports.typeFinancial = typeFinancial;
const getExtinctionRiskLevelOptions = () => (0, exports.createOptions)(activity_1.ExtinctionRiskLevels, 'TOPICS:NATURE_LOSS.EXTINCTION_RISK_LEVELS');
exports.getExtinctionRiskLevelOptions = getExtinctionRiskLevelOptions;
const getOwnershipStatusOptions = () => (0, exports.createOptions)(activity_1.OwnershipStatuses, 'TOPICS:NATURE_LOSS.OWNERSHIP_STATUSES');
exports.getOwnershipStatusOptions = getOwnershipStatusOptions;
const getSubsurfaceLandStatusOptions = () => (0, exports.createOptions)(activity_1.SubsurfaceLandStatuses, 'TOPICS:NATURE_LOSS.SUBSURFACE_LAND_STATUSES');
exports.getSubsurfaceLandStatusOptions = getSubsurfaceLandStatusOptions;
const getRelationToProtectedAreaOptions = () => (0, exports.createOptions)(activity_1.RelationToProtectedArea, 'TOPICS:NATURE_LOSS.RELATION_TO_PROTECTED_AREA_OPTIONS');
exports.getRelationToProtectedAreaOptions = getRelationToProtectedAreaOptions;
const getOperationTypeOptions = () => (0, exports.createOptions)(activity_1.OperationTypes, 'TOPICS:NATURE_LOSS.OPERATION_TYPES');
exports.getOperationTypeOptions = getOperationTypeOptions;
const getBiodiversityValueAttributeOptions = () => (0, exports.createOptions)(activity_1.BiodiversityValueAttributes, 'TOPICS:NATURE_LOSS.BIODIVERSITY_VALUE_ATTRIBUTES');
exports.getBiodiversityValueAttributeOptions = getBiodiversityValueAttributeOptions;
const getImpactNatureOptions = () => (0, exports.createOptions)(activity_1.ImpactNatureTypes, 'TOPICS:NATURE_LOSS.IMPACT_NATURE_TYPES');
exports.getImpactNatureOptions = getImpactNatureOptions;
const getImpactTypeOptions = () => (0, exports.createOptions)(activity_1.ImpactTypes, 'TOPICS:NATURE_LOSS.IMPACT_TYPES');
exports.getImpactTypeOptions = getImpactTypeOptions;
const getImpactReversibilityOptions = () => (0, exports.createOptions)(activity_1.ImpactReversibilityTypes, 'TOPICS:NATURE_LOSS.IMPACT_REVERSIBILITY_TYPES');
exports.getImpactReversibilityOptions = getImpactReversibilityOptions;
const getRestorationSuccessOptions = () => (0, exports.createOptions)(activity_1.RestorationSuccessTypes, 'TOPICS:NATURE_LOSS.RESTORATION_SUCCESS_TYPES');
exports.getRestorationSuccessOptions = getRestorationSuccessOptions;
const getPartnershipStatusOptions = () => (0, exports.createOptions)(activity_1.PartnershipStatuses, 'TOPICS:NATURE_LOSS.PARTNERSHIP_STATUSES');
exports.getPartnershipStatusOptions = getPartnershipStatusOptions;
const impactCategory = () => (0, exports.createOptions)(activity_1.ImpactCategory, 'TOPICS:LOCAL_COMMUNITIES.IMPACT_CATEGORY');
exports.impactCategory = impactCategory;
const impactType = () => (0, exports.createOptions)(activity_1.LocalCommunityImpactType, 'TOPICS:LOCAL_COMMUNITIES.IMPACT_TYPES');
exports.impactType = impactType;
const impactFactor = () => (0, exports.createOptions)(activity_1.ImpactFactor, 'TOPICS:LOCAL_COMMUNITIES.IMPACT_FACTORS');
exports.impactFactor = impactFactor;
const impactExposure = () => (0, exports.createOptions)(activity_1.ImpactExposure, 'TOPICS:LOCAL_COMMUNITIES.IMPACT_EXPOSURE');
exports.impactExposure = impactExposure;
const impactSeverity = () => (0, exports.createOptions)(activity_1.ImpactSeverity, 'TOPICS:LOCAL_COMMUNITIES.IMPACT_SEVERITY');
exports.impactSeverity = impactSeverity;
const impactScale = () => (0, exports.createOptions)(activity_1.ImpactScale, 'TOPICS:LOCAL_COMMUNITIES.IMPACT_SCALE');
exports.impactScale = impactScale;
const reversibility = () => (0, exports.createOptions)(activity_1.Reversibility, 'TOPICS:LOCAL_COMMUNITIES.REVERSIBILITY');
exports.reversibility = reversibility;
const coverageCategory = () => (0, exports.createOptions)(activity_1.CoverageCategory, 'TOPICS:LOCAL_COMMUNITIES.COVERAGE_CATEGORIES');
exports.coverageCategory = coverageCategory;
const stakeholderSegmentBasis = () => (0, exports.createOptions)(activity_1.StakeholderSegmentBasis, 'TOPICS:LOCAL_COMMUNITIES.STAKEHOLDER_SEGMENT_BASIS');
exports.stakeholderSegmentBasis = stakeholderSegmentBasis;
const stakeholderSegment = () => (0, exports.createOptions)(activity_1.StakeholderSegment, 'TOPICS:LOCAL_COMMUNITIES.STAKEHOLDER_SEGMENT');
exports.stakeholderSegment = stakeholderSegment;
const materialityContactType = () => (0, exports.createOptions)(activity_1.MaterialityContactType, 'CONTACTS.USER_TYPES');
exports.materialityContactType = materialityContactType;
