import { Component, Input } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { NzAvatarComponent } from 'ng-zorro-antd/avatar';
import { ShowUserAvatarPipe } from '@ieShared/pipes/show-user-avatar.pipe';
import { NzSizeLDSType } from 'ng-zorro-antd/core/types';

@Component({
  selector: 'app-avatar',
  standalone: true,
  imports: [AsyncPipe, NzAvatarComponent, ShowUserAvatarPipe],
  templateUrl: './avatar.component.html',
  styleUrl: './avatar.component.scss',
})
export class AvatarComponent {
  @Input() src!: string;
  @Input() txt: string = 'N';
  @Input() size: NzSizeLDSType | number = 'default';

  getAsString(v: unknown): string {
    return v as string;
  }
}
