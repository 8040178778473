"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ContactStatus = void 0;
var ContactStatus;
(function (ContactStatus) {
  ContactStatus["ACTIVE"] = "ACTIVE";
  ContactStatus["INACTIVE"] = "INACTIVE";
})(ContactStatus || (exports.ContactStatus = ContactStatus = {}));
