import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable, of, startWith } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { StorageService } from '@ieCore/services/storage.service';
import { StorageDirectory } from 'shared-general-libs/enum/storage';

@Pipe({
  name: 'showUserAvatar',
  standalone: true,
})
export class ShowUserAvatarPipe implements PipeTransform {
  constructor(
    private storageService: StorageService,
    private sanitizer: DomSanitizer,
  ) {}

  transform(imageId: string): Observable<SafeUrl | string | null> {
    return this.storageService
      .loadImageForPreview(StorageDirectory.AVATARS, imageId)
      .pipe(
        map((blob) => {
          const objectURL = URL.createObjectURL(blob);
          return this.sanitizer.bypassSecurityTrustUrl(objectURL);
        }),
        startWith(null),
        catchError(() => of('assets/icon/media/no-image.png')), // Optional: handle errors
      );
  }
}
