"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RenewableStatus = void 0;
var RenewableStatus;
(function (RenewableStatus) {
  RenewableStatus["RENEWABLE"] = "RENEWABLE";
  RenewableStatus["NON_RENEWABLE"] = "NON_RENEWABLE";
})(RenewableStatus || (exports.RenewableStatus = RenewableStatus = {}));
