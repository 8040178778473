"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WaterCategory = void 0;
var WaterCategory;
(function (WaterCategory) {
  WaterCategory["FRESH_WATER"] = "Fresh Water";
  WaterCategory["OTHER_WATER"] = "Other Water";
})(WaterCategory || (exports.WaterCategory = WaterCategory = {}));
