"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TrianingEmployeeCategory = void 0;
var TrianingEmployeeCategory;
(function (TrianingEmployeeCategory) {
  TrianingEmployeeCategory["SENIOR_MANAGEMENT"] = "SENIOR_MANAGEMENT";
  TrianingEmployeeCategory["MIDDLE_MANAGEMENT"] = "MIDDLE_MANAGEMENT";
  TrianingEmployeeCategory["TECHNICAL"] = "TECHNICAL";
  TrianingEmployeeCategory["ADMINISTRATIVE"] = "ADMINISTRATIVE";
  TrianingEmployeeCategory["PRODUCTION"] = "PRODUCTION";
})(TrianingEmployeeCategory || (exports.TrianingEmployeeCategory = TrianingEmployeeCategory = {}));
