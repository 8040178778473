"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WaterArea = void 0;
var WaterArea;
(function (WaterArea) {
  WaterArea["ALL"] = "All";
  WaterArea["AREAS_WITH_WATER_STRESS"] = "Areas with Water Stress";
})(WaterArea || (exports.WaterArea = WaterArea = {}));
