"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MaterialCategory = void 0;
var MaterialCategory;
(function (MaterialCategory) {
  MaterialCategory["RAW_MATERIALS"] = "Raw Materials";
  MaterialCategory["PROCESS_MATERIALS"] = "Process Materials";
  MaterialCategory["SEMI_MANUFACTURED_GOODS"] = "Semi-manufactured Goods";
  MaterialCategory["PACKAGING_MATERIALS"] = "Packaging Materials";
})(MaterialCategory || (exports.MaterialCategory = MaterialCategory = {}));
