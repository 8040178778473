"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WaterSource = void 0;
var WaterSource;
(function (WaterSource) {
  WaterSource["SURFACE_WATER"] = "Surface water";
  WaterSource["GROUNDWATER"] = "Groundwater";
  WaterSource["SEAWATER"] = "Seawater";
  WaterSource["PRODUCED_WATER"] = "Produced water";
})(WaterSource || (exports.WaterSource = WaterSource = {}));
