"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TopicPriority = exports.ImportanceType = void 0;
var ImportanceType;
(function (ImportanceType) {
  ImportanceType["BUSINESS"] = "BUSINESS";
  ImportanceType["STAKEHOLDERS"] = "STAKEHOLDERS";
})(ImportanceType || (exports.ImportanceType = ImportanceType = {}));
var TopicPriority;
(function (TopicPriority) {
  TopicPriority["LOW"] = "LOW";
  TopicPriority["MEDIUM"] = "MEDIUM";
  TopicPriority["HIGH"] = "HIGH";
})(TopicPriority || (exports.TopicPriority = TopicPriority = {}));
