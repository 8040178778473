import { Injectable } from '@angular/core';
import { StorageRepository } from '@ieCore/repositories/storage.repository';
import { StorageDirectory } from 'shared-general-libs/enum/storage';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor(private storageRepository: StorageRepository) {}

  getUploadLink(dir: StorageDirectory): string {
    return this.storageRepository.getUploadLink(dir);
  }

  getDownloadLink(dir: StorageDirectory, path: string): string {
    return this.storageRepository.getDownloadLink(dir, path);
  }

  loadImage(dir: StorageDirectory, path: string, name: string): void {
    return this.storageRepository.loadImage(dir, path, name);
  }

  loadImageForPreview(dir: StorageDirectory, path: string): Observable<Blob> {
    return this.storageRepository.loadImageForPreview(dir, path);
  }
}
