"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DiversityValuesMapping = void 0;
const activity_1 = require("../enum/activity");
const utils_1 = require("../utils");
exports.DiversityValuesMapping = {
  [activity_1.DiversityIndicators.ETHNICITY]: (0, utils_1.getEthnicityOptions)(),
  [activity_1.DiversityIndicators.DISABILITY]: (0, utils_1.getDisabilityOptions)(),
  [activity_1.DiversityIndicators.EDUCATIONAL_LEVEL]: (0, utils_1.getEducationalLevelOptions)(),
  [activity_1.DiversityIndicators.NATIONALITY]: (0, utils_1.getNationalityOptions)(),
  [activity_1.DiversityIndicators.INDIGENOUS_STATUS]: (0, utils_1.getIndigenousStatusOptions)(),
  [activity_1.DiversityIndicators.VETERAN_STATUS]: (0, utils_1.getVeteranStatusOptions)(),
  [activity_1.DiversityIndicators.LGBTQ_IDENTITY]: (0, utils_1.getLGBTQIdentityOptions)(),
  [activity_1.DiversityIndicators.SOCIOECONOMIC_STATUS]: (0, utils_1.getSocioeconomicStatusOptions)(),
  [activity_1.DiversityIndicators.RELIGION]: (0, utils_1.getReligionOptions)(),
  [activity_1.DiversityIndicators.MARITAL_STATUS]: (0, utils_1.getMaritalStatusOptions)()
};
