"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formFunctionMapping = exports.dependOnOtherField = exports.calcEconomicValueRetained = exports.createRequestEndDate = exports.percent = exports.divide = exports.multiply = void 0;
const enum_1 = require("../enum");
const period_1 = require("../enum/period");
const utl_1 = require("./utl");
const multiply = (formData, keys) => {
  const [num1Key, num2Key] = keys;
  const num1 = formData[num1Key];
  const num2 = formData[num2Key];
  let value = 0;
  if (typeof num1 === 'number' && typeof num2 === 'number') {
    value = num1 * num2;
  }
  return {
    value: (0, utl_1.formatNumberWithPrecision)(value),
    disabled: true
  };
};
exports.multiply = multiply;
const divide = (formData, keys) => {
  const [num1Key, num2Key] = keys;
  let value = 0;
  if (formData[num1Key] && formData[num2Key]) {
    value = +formData[num1Key] / +formData[num2Key];
  }
  return {
    value: (0, utl_1.formatNumberWithPrecision)(value),
    disabled: true
  };
};
exports.divide = divide;
const percent = (formData, keys) => {
  const [num1Key, num2Key] = keys;
  let value = 0;
  if (formData[num1Key] && formData[num2Key]) {
    value = +formData[num2Key] * 100 / +formData[num1Key];
  }
  return {
    value: (0, utl_1.formatNumberWithPrecision)(value),
    disabled: true
  };
};
exports.percent = percent;
const createRequestEndDate = (formData, keys) => {
  let value = null;
  const reportingPeriod = formData[keys[0]];
  if (reportingPeriod && formData['startDate']) {
    value = new Date(formData['startDate']);
    switch (reportingPeriod) {
      case period_1.ReportingPeriod.MONTHLY:
        value = new Date(value.getFullYear(), value.getMonth() + 1, 0);
        value.setHours(23, 59, 59, 999);
        break;
      case period_1.ReportingPeriod.QUARTERLY:
        value = new Date(value.getFullYear(), value.getMonth() + 3, 0);
        value.setHours(23, 59, 59, 999);
        break;
      case period_1.ReportingPeriod.HALF_YEARLY:
        value = new Date(value.getFullYear(), value.getMonth() + 6, 0);
        value.setHours(23, 59, 59, 999);
        break;
      case period_1.ReportingPeriod.YEARLY:
        value = new Date(formData['startDate']);
        value.setFullYear(value.getFullYear() + 1, value.getMonth(), 0);
        value.setHours(23, 59, 59, 999);
        break;
    }
  }
  return {
    value,
    disabled: true
  };
};
exports.createRequestEndDate = createRequestEndDate;
const calcEconomicValueRetained = (formData, keys) => {
  const [revenue, operatingCost, employeeWagesAndBenefits, donationsAndCommunityInvestments, paymentsToProvidersOfCapital, paymentsToGovernment] = keys;
  let value = 0;
  const allDefined = keys.every(key => formData[key] !== undefined);
  if (allDefined) {
    value = +formData[revenue] - (+formData[operatingCost] + +formData[employeeWagesAndBenefits] + +formData[donationsAndCommunityInvestments] + +formData[paymentsToProvidersOfCapital] + +formData[paymentsToGovernment]);
  }
  return {
    value: (0, utl_1.formatNumberWithPrecision)(value, 2),
    disabled: true
  };
};
exports.calcEconomicValueRetained = calcEconomicValueRetained;
const dependOnOtherField = (formData, keys) => {
  const [val1Key] = keys;
  const val1 = formData[val1Key];
  if (val1) {
    return {
      disabled: false,
      value: val1
    };
  } else {
    return {
      disabled: true,
      value: null
    };
  }
};
exports.dependOnOtherField = dependOnOtherField;
exports.formFunctionMapping = {
  [enum_1.FormOnChangeFunctions.MULTIPLY]: exports.multiply,
  [enum_1.FormOnChangeFunctions.DIVIDE]: exports.divide,
  [enum_1.FormOnChangeFunctions.PERCENT]: exports.percent,
  [enum_1.FormOnChangeFunctions.CNR_END_DATE]: exports.createRequestEndDate,
  [enum_1.FormOnChangeFunctions.ECONOMIC_VALUE_RETAINED]: exports.calcEconomicValueRetained,
  [enum_1.FormOnChangeFunctions.DEPENDS_ON_OTHER_FIELD]: exports.dependOnOtherField
};
