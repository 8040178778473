"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SourceOfMaterial = void 0;
var SourceOfMaterial;
(function (SourceOfMaterial) {
  SourceOfMaterial["EXTERNAL_SUPPLIERS"] = "EXTERNAL_SUPPLIERS";
  SourceOfMaterial["INTERNAL"] = "INTERNAL";
})(SourceOfMaterial || (exports.SourceOfMaterial = SourceOfMaterial = {}));
