"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TreatmentLevel = void 0;
var TreatmentLevel;
(function (TreatmentLevel) {
  TreatmentLevel["PRIMARY"] = "Primary";
  TreatmentLevel["SECONDARY"] = "Secondary";
  TreatmentLevel["TERTIARY"] = "Tertiary";
})(TreatmentLevel || (exports.TreatmentLevel = TreatmentLevel = {}));
