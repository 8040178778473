import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@ieEnv/environment';
import { StorageDirectory } from 'shared-general-libs/enum/storage';

@Injectable({
  providedIn: 'root',
})
export class StorageRepository {
  private readonly apiUrl: string = environment.storageHost;

  constructor(private http: HttpClient) {}

  getUploadLink(dir: StorageDirectory) {
    return `${this.apiUrl}/storage/${dir}`;
  }

  getDownloadLink(dir: StorageDirectory, path: string) {
    return `${this.apiUrl}/storage/${dir}/${path}`;
  }

  delete(dir: StorageDirectory, path: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/storage/${dir}/${path}`);
  }

  loadImage(dir: StorageDirectory, path: string, name: string): void {
    this.http
      .get(this.getDownloadLink(dir, path), {
        responseType: 'blob',
      })
      .subscribe((blob) => {
        const downloadURL = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadURL;
        link.download = name;
        link.click();
        URL.revokeObjectURL(downloadURL);
      });
  }

  loadImageForPreview(dir: StorageDirectory, path: string): Observable<Blob> {
    return this.http.get(this.getDownloadLink(dir, path), {
      responseType: 'blob',
    });
  }
}
