"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TransitionAssistanceProgramType = void 0;
var TransitionAssistanceProgramType;
(function (TransitionAssistanceProgramType) {
  TransitionAssistanceProgramType["PRE_RETIREMENT_PLANNING"] = "PRE_RETIREMENT_PLANNING";
  TransitionAssistanceProgramType["RETRAINING"] = "RETRAINING";
  TransitionAssistanceProgramType["SEVERANCE_PAY"] = "SEVERANCE_PAY";
  TransitionAssistanceProgramType["JOB_PLACEMENT_SERVICES"] = "JOB_PLACEMENT_SERVICES";
  TransitionAssistanceProgramType["COUNSELING"] = "COUNSELING";
  TransitionAssistanceProgramType["OTHER"] = "OTHER";
})(TransitionAssistanceProgramType || (exports.TransitionAssistanceProgramType = TransitionAssistanceProgramType = {}));
