"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ProductCategory = void 0;
var ProductCategory;
(function (ProductCategory) {
  ProductCategory["PURCHASED_GOOD"] = "PURCHASED_GOOD";
  ProductCategory["CAPITAL_GOOD"] = "CAPITAL_GOOD";
  ProductCategory["MANUFACTURED_GOOD"] = "MANUFACTURED_GOOD";
  ProductCategory["SERVICE"] = "SERVICE";
})(ProductCategory || (exports.ProductCategory = ProductCategory = {}));
