"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TrainingProgramType = void 0;
var TrainingProgramType;
(function (TrainingProgramType) {
  TrainingProgramType["INTERNAL_TRAINING_COURSES"] = "INTERNAL_TRAINING_COURSES";
  TrainingProgramType["FUNDING_FOR_EXTERNAL_TRAINING"] = "FUNDING_FOR_EXTERNAL_TRAINING";
  TrainingProgramType["SABBATICALS"] = "SABBATICALS";
  TrainingProgramType["OTHER"] = "OTHER";
})(TrainingProgramType || (exports.TrainingProgramType = TrainingProgramType = {}));
