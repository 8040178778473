"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ProductStatus = void 0;
var ProductStatus;
(function (ProductStatus) {
  ProductStatus["ACTIVE"] = "ACTIVE";
  ProductStatus["INACTIVE"] = "INACTIVE";
})(ProductStatus || (exports.ProductStatus = ProductStatus = {}));
