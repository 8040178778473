"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NoteType = void 0;
var NoteType;
(function (NoteType) {
  NoteType["ACTIVITY"] = "ACTIVITY";
})(NoteType || (exports.NoteType = NoteType = {}));
